import React from 'react';
import classNames from 'classnames';

import styles from './ProgressBar.module.sass';

class ProgressBar extends React.Component {
  render() {
    const { percent = 0 } = this.props;
    return (
      <div>
        <div className={styles.progressWrapper}>
          <div className={styles.progress} ref={this.progressRef}>
            <div
              className={classNames(styles.progressBar, {
                [styles.progressBarLow]: percent >= 0 && percent <= 33,
                [styles.progressBarMedium]: percent > 33 && percent <= 66,
                [styles.progressBarHigh]: percent >= 66 && percent <= 100
              })}
              style={{ width: `${percent}%`, visibility: percent === 0 ? 'hidden' : 'visible' }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ProgressBar;
