/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import classNames from 'classnames';

import Separator from '../Separator';

import styles from './Section.module.sass';

const Section = ({ type = 'normal', title, subtitle, showSeparator = true, children, ...rest }) => (
  <div
    className={classNames(styles.section, {
      [styles.sectionGray]: type === 'gray',
      [styles.sectionDark]: type === 'dark'
    })}
    {...rest}
  >
    {title && (
      <React.Fragment>
        <div className={styles.title}>
          <h2>{title}</h2>
        </div>
        {subtitle && (
          <div className={styles.subtitle}>
            <div>{subtitle}</div>
          </div>
        )}
        {showSeparator && <Separator />}
      </React.Fragment>
    )}
    <div>{children}</div>
  </div>
);

export default Section;
