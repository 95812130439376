function deUmlaut(value = '') {
  return value
    .toLowerCase()
    .replace(/ä/g, 'ae')
    .replace(/ö/g, 'oe')
    .replace(/ü/g, 'ue')
    .replace(/ß/g, 'ss')
    .replace(/ /g, '-')
    .replace(/\./g, '')
    .replace(/,/g, '')
    .replace(/\(/g, '')
    .replace(/\)/g, '');
}

function slugify(text = '') {
  const replacedUmlaut = deUmlaut(text);
  return replacedUmlaut
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

export default slugify;
