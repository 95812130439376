import React from 'react';
import classNames from 'classnames';

import styles from './Heart.module.sass';

class Heart extends React.Component {
  state = {
    counter: 0
  };

  componentDidMount() {
    const { text } = this.props;

    if (text) {
      this.timer = setInterval(this.incrementCounter, 40);
    }
  }

  componentWillUnmount() {
    const { text } = this.props;

    if (text) {
      clearInterval(this.timer);
    }
  }

  incrementCounter = () => {
    const { text } = this.props;
    const { counter } = this.state;
    // eslint-disable-next-line radix
    const end = parseInt(text);
    const increment = 1;

    if (counter < end) {
      this.setState(
        prevState => ({
          ...prevState,
          counter: prevState.counter + increment
        }),
        () => {
          if (counter === end - 5) {
            clearInterval(this.timer);
            this.timer = setInterval(this.incrementCounter, 200);
          }
          if (counter === end) {
            clearInterval(this.timer);
          }
        }
      );
    }
  };

  render() {
    const { text, scale = 1, animate, textStyle, ...rest } = this.props;
    const { counter } = this.state;

    return (
      <div
        className={classNames(styles.heartWrapper, {
          [animate]: styles.rotation
        })}
        {...rest}
      >
        <div
          className={styles.heart}
          style={{
            transform: `rotate(45deg) scale(${scale})`,
            position: text && 'relative'
          }}
        />
        {text && (
          <div className={styles.heartText} style={textStyle}>
            {counter}
          </div>
        )}
      </div>
    );
  }
}
export default Heart;
