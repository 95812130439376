import React, { Component } from 'react';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  Cell,
  XAxis
} from 'recharts';

import Header from '../../shared/components/Header';
import HeartScore from '../../shared/components/HeartScore';
import Section from '../../shared/components/Section';
import RadarChart from '../../shared/components/RadarChart';
import Table from '../Table';
import styles from './Report.module.sass';
import Scala from '../Scala';
import { apiUrl, request } from '../../shared/lib/request';
import slugify from '../../shared/lib/slugify';
import Button from '../../shared/components/Button';

class Report extends Component {
  state = {
    questionnaire: {},
    questions: [],
    scoreData: {},
    formNameMap: {},
    width: 0,
    getMoreInfoSuccess: null
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  async componentDidMount() {
    const {
      match: {
        params: { reportId }
      }
    } = this.props;

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    try {
      const questionnaireResult = await request(`${apiUrl}/questionnaire/${reportId}`, {
        method: 'GET'
      });
      const {
        questionnaire,
        benchmarks,
        scores,
        isCompanyQuestionnaire,
        buckets
      } = questionnaireResult;
      const questionsResult = await request(`${apiUrl}/questionnaire/questions`, {
        method: 'GET'
      });
      const { questions } = questionsResult;
      const benchmarkOptionsResponse = await request(`${apiUrl}/questionnaire/benchmarkOptions`, {
        method: 'GET'
      });
      const { benchmarkOptions } = benchmarkOptionsResponse;
      const {
        ageValues,
        industryValues,
        companySizeValues,
        companyLocationValues,
        companyDurationValues,
        positionValues,
        incomeValues,
        functionAreaValues,
        genderValues
      } = benchmarkOptions;

      this.setState(prevState => ({
        ...prevState,
        questionnaire,
        questions,
        isCompanyQuestionnaire,
        buckets,
        benchmarkOptions: {
          ageValues,
          industryValues,
          companySizeValues,
          companyLocationValues,
          companyDurationValues,
          positionValues,
          incomeValues,
          functionAreaValues,
          genderValues
        },
        benchmarks,
        scoreData: scores
      }));
    } catch (e) {
      console.log(e);
    }
  }

  getScoreExplanationText = score => {
    if (score <= 20) {
      return {
        title: 'Bad Job!',
        text: (
          <div>
            <p>
              Der Score zeigt eine große Abweichung zwischen SOLL und IST - die Bedürfnisse werden
              aktuell noch nicht erfüllt. <br />
              Der individuelle Report verrät, wo die Lücken am größten sind, damit der Bad Job zum
              Good Job wird!
            </p>
          </div>
        )
      };
    }

    if (score > 20 && score <= 40) {
      return {
        title: 'Der Anfang ist gemacht!',
        text: (
          <div>
            <p>
              Der Score zeigt eine deutliche Abweichung zwischen SOLL und IST - die Bedürfnisse
              werden oftmals noch nicht erfüllt.
              <br />
              Der individuelle Report verrät, wo noch Lücken sind, damit der Job zum Good Job wird!
            </p>
          </div>
        )
      };
    }

    if (score > 40 && score <= 60) {
      return {
        title: 'Nicht schlecht!',
        text: (
          <div>
            <p>
              Der Score zeigt eine mittlere Abweichung zwischen SOLL und IST - die Bedürfnisse
              werden nicht immer voll erfüllt.
              <br />
              Der individuelle Report verrät, wo noch Lücken sind, damit der Job zum Good Job wird!
            </p>
          </div>
        )
      };
    }

    if (score > 60 && score <= 80) {
      return {
        title: 'Fast perfekt!',
        text: (
          <div>
            <p>
              Der Score zeigt eine mittlere Abweichung zwischen SOLL und IST - die Bedürfnisse
              werden nicht immer voll erfüllt.
              <br />
              Der individuelle Report verrät, wo noch Lücken sind, damit der Job zum Good Job wird!
            </p>
          </div>
        )
      };
    }

    if (score > 80 && score <= 100) {
      return {
        title: 'Good Job!',
        text: (
          <div>
            <p>
              Herzlichen Glückwunsch, du hast einen Good Job! Der Score zeigt nur eine geringe
              Abweichung zwischen SOLL und IST - die Bedürfnisse werden schon sehr gut erfüllt.
              <br />
              Der individuelle Report verrät dir, wo vielleicht dennoch Lücken sind, damit der Good
              Job auch ein Good Job bleibt!
            </p>
          </div>
        )
      };
    }
  };

  transformScoreDataToRadarData = () => {
    const { questionnaire, scoreData } = this.state;

    if (Object.keys(questionnaire).length === 0) {
      return null;
    }

    const transformedRadarData = Object.keys(scoreData.scores).reduce((prevValue, groupName) => {
      const key = slugify(groupName);
      const groupValues = scoreData.scores[groupName];
      const istAvgValue = groupValues.ist.average;
      const sollAvgValue = groupValues.soll.average;

      return {
        ...prevValue,
        ist: {
          ...prevValue.ist,
          values: {
            ...(prevValue.ist && prevValue.ist.values),
            [key]: istAvgValue
          },
          meta: {
            color: '#EFAA1F',
            filterKey: 'istFilter'
          }
        },
        soll: {
          values: {
            ...(prevValue.soll && prevValue.soll.values),
            [key]: sollAvgValue
          },
          meta: {
            color: '#36558F',
            filterKey: 'sollFilter'
          }
        }
      };
    }, {});

    const transformedRadarDataArray = Object.keys(transformedRadarData).map(
      key => transformedRadarData[key]
    );

    return transformedRadarDataArray;
  };

  render() {
    const { questionnaire, questions, scoreData, width, benchmarks, benchmarkOptions } = this.state;
    const { getMoreInfoSuccess } = this.state;

    if (Object.keys(questionnaire).length === 0) {
      return null;
    }

    const radarData = this.transformScoreDataToRadarData();

    const legends = questions.reduce((prevValue, questionGroup) => {
      const istQuestion = questionGroup.questions.find(question => question.category === 'ist');
      const questionLegends = [istQuestion.meta.labelLeft, istQuestion.meta.labelRight];

      return {
        ...prevValue,
        [slugify(questionGroup.title)]: questionLegends
      };
    }, {});

    const radarOptions = {
      labels: {
        [slugify('Recruiting')]: width <= 768 ? 'REC' : 'Recruiting',
        [slugify('Motivation')]: width <= 768 ? 'MOT' : 'Motivation',
        [slugify('Entwicklung')]: width <= 768 ? 'ENT' : 'Entwicklung',
        [slugify('Führung')]: width <= 768 ? 'FÜH' : 'Führung',
        [slugify('Steuerung')]: width <= 768 ? 'STE' : 'Steuerung',
        [slugify('Arbeitsplatz')]: width <= 768 ? 'ARB' : 'Arbeitsplatz',
        [slugify('Work Life Organisation')]: width <= 768 ? 'WLO' : 'Work Life Organisation'
      },
      legends,
      dataOrder: [
        slugify('Recruiting'),
        slugify('Motivation'),
        slugify('Entwicklung'),
        slugify('Führung'),
        slugify('Steuerung'),
        slugify('Arbeitsplatz'),
        slugify('Work Life Organisation')
      ],
      scala: [0, 100],
      radarSize: 500,
      enableFilter: true,
      filterOptions: {
        labels: {
          all: 'Ist- und Soll-Werte',
          istFilter: 'Ist-Werte',
          sollFilter: 'Soll-Werte'
        },
        defaultFilter: 'all'
      }
    };

    const score = Math.round(parseFloat(scoreData.totalScorePercent) * 100);
    const scoreExplanation = this.getScoreExplanationText(score);

    return (
      <div className={styles.wrapper}>
        <Header title="Good Job! Report" />
        <div className={styles.content}>
          <Section showSeparator={false}>
            <HeartScore
              score={score}
              scale={1.5}
              wrapperStyle={{
                marginLeft: '1.5rem',
                marginRight: '1.5rem'
              }}
              textStyle={{
                fontSize: '2rem'
              }}
            />

            <div className={styles.scoreExplanationWrapper}>
              <strong>{scoreExplanation && scoreExplanation.title}</strong>

              {scoreExplanation && scoreExplanation.text && <div>{scoreExplanation.text}</div>}
            </div>
          </Section>
          <Section type="gray" title="Dimensionen">
            <div style={{ textAlign: 'center' }}>
              <p>
                Die Tabelle zeigt die Übereinstimmungen zwischen SOLL und IST in jeder der
                abgefragten Dimensionen an. 100 = volle Übereinstimmung.
                <br />
                Die Benchmarks zeigen die Übereinstimmungen zwischen SOLL und IST in den
                ausgewählten Vergleichsgruppen über die verschiedenen Dimensionen hinweg an. Dies
                erlaubt die Einordnung der eigenen Werte: Höherer Wert als Benchmark = Bessere
                Übereinstimmung
              </p>
              <br />
            </div>
            <Table
              data={scoreData}
              questions={questions}
              benchmarks={benchmarks}
              benchmarkOptions={benchmarkOptions}
            />
          </Section>
          <Section title="Good Job Potenziale" subtitle="(Orange  = Ist-Werte | Blau = Soll-Werte)">
            <div style={{ textAlign: 'center' }}>
              <p>
                Das Netzdiagramm zeigt Ihnen anhand Ihrer persönlichen SOLL- und IST Werte auf einen
                Blick an, in welchen Good Job Dimensionen die größten Abweichungen und somit
                Potenziale liegen. Mit welchen Maßnahmen könnten Sie die IST-Werte in Richtung der
                SOLL-Werte verschieben?
              </p>
            </div>
            <div className={styles.chartWrapper}>
              <RadarChart data={radarData} options={radarOptions} />
            </div>
          </Section>
          <Section
            type="gray"
            showSeparator={false}
            title="Individuelle Skalen"
            subtitle="(Orange  = Ist-Werte | Blau = Soll-Werte)"
          >
            <div style={{ textAlign: 'center' }}>
              <p>
                Die Skalen zeigen die individuelle SOLL und IST Positionierung in jeder Dimension
                an. Auf diese Weise lassen sich die GAPS inhaltlich diskutieren und
                Veränderungsmöglichkeiten werden aufgezeigt.
                <br />
                Genaue Maßnahmen benötigen jedoch eine tiefergehende Analyse.
              </p>
            </div>
          </Section>
          <div>{this.renderIndividualScalas()}</div>
          <Section
            title="Mit dem Good Job! Audit auf die Reise in die neue Arbeitswelt"
            showSeparator
          >
            <div style={{ textAlign: 'center' }}>
              <p>
                Hat Ihnen das Good Job! Audit geholfen, Bedürfnisse zu erkennen und mit der
                aktuellen Arbeitssituation zu vergleichen? Wir freuen uns auf Ihr Feedback an{' '}
                <a href="mailto:audit@goodjob.jetzt">audit@goodjob.jetzt</a> <br />
                Gerne führen wir das Good Job! Audit auch mit erweiterten Auswertungsmöglichkeiten
                für Teams auch in Ihrem Unternehmen durch. Fordern Sie hier Ihre weitere
                Informationen an:
              </p>

              {getMoreInfoSuccess && (
                <div className={styles.success}>Vielen Dank für Ihre Teilnahme!</div>
              )}
              <Button onClick={this.getMoreInfo}>Informationen anfordern</Button>
            </div>
          </Section>
        </div>
      </div>
    );
  }

  renderIndividualScalas = () => {
    const { questions, scoreData, isCompanyQuestionnaire, buckets } = this.state;

    // questions is sorted with groups, so we map through this
    // so that we display the scalas in the sorted way
    if (scoreData && scoreData.scores) {
      return questions.map((questionGroup, index) => {
        const { title } = questionGroup;
        const istValue = scoreData.scores[title].ist.average;
        const sollValue = scoreData.scores[title].soll.average;
        const istQuestion = questionGroup.questions.find(question => question.category === 'ist');
        let data = [];

        if (isCompanyQuestionnaire) {
          const bucket = buckets[title];

          data = [
            {
              name: '[0-10]',
              IST: bucket['ist']['BUCKET_0_10'],
              SOLL: bucket['soll']['BUCKET_0_10']
            },
            {
              name: '[11-20]',
              IST: bucket['ist']['BUCKET_11_20'],
              SOLL: bucket['soll']['BUCKET_11_20']
            },
            {
              name: '[21-30]',
              IST: bucket['ist']['BUCKET_21_30'],
              SOLL: bucket['soll']['BUCKET_21_30']
            },
            {
              name: '[31-40]',
              IST: bucket['ist']['BUCKET_31_40'],
              SOLL: bucket['soll']['BUCKET_31_40']
            },
            {
              name: '[41-50]',
              IST: bucket['ist']['BUCKET_41_50'],
              SOLL: bucket['soll']['BUCKET_41_50']
            },
            {
              name: '[51-60]',
              IST: bucket['ist']['BUCKET_51_60'],
              SOLL: bucket['soll']['BUCKET_51_60']
            },
            {
              name: '[61-70]',
              IST: bucket['ist']['BUCKET_61_70'],
              SOLL: bucket['soll']['BUCKET_61_70']
            },
            {
              name: '[71-80]',
              IST: bucket['ist']['BUCKET_71_80'],
              SOLL: bucket['soll']['BUCKET_71_80']
            },
            {
              name: '[81-90]',
              IST: bucket['ist']['BUCKET_81_90'],
              SOLL: bucket['soll']['BUCKET_81_90']
            },
            {
              name: '[91-100]',
              IST: bucket['ist']['BUCKET_91_100'],
              SOLL: bucket['soll']['BUCKET_91_100']
            }
          ];
        }

        return (
          <Section type={index % 2 === 0 ? 'gray' : null} showSeparator={false} key={title}>
            <Scala
              title={title}
              istValue={istValue}
              sollValue={sollValue}
              values={istQuestion.meta.values}
              labels={[istQuestion.meta.labelLeft, istQuestion.meta.labelRight]}
              isCompanyQuestionnaire={isCompanyQuestionnaire}
            />
            {isCompanyQuestionnaire && (
              <div className={styles.lineChartWrapper}>
                <h3>Streuungsmaß</h3>

                <ResponsiveContainer>
                  <BarChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="SOLL" fill="#1976d2" />
                    <Bar dataKey="IST" fill="#ff6f00" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            )}
          </Section>
        );
      });
    }
  };

  getMoreInfo = async () => {
    const {
      match: {
        params: { reportId }
      }
    } = this.props;
    const { getMoreInfoSuccess } = this.state;

    if (getMoreInfoSuccess !== true && reportId !== 'demo') {
      try {
        await request(`${apiUrl}/getMoreInfo`, {
          method: 'POST',
          body: {
            reportId
          }
        });

        this.setState(prevState => ({
          ...prevState,
          getMoreInfoSuccess: true
        }));
      } catch (e) {
        this.setState(prevState => ({
          ...prevState,
          getMoreInfoSuccess: false
        }));
      }
    }
  };
}

export default Report;
