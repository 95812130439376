import React from 'react';

export default class TextLabel extends React.Component {
  render() {
    const {
      children,
      coordinate,
      centerX,
      centerY,
      marginVertical = 0,
      marginHorizontal = 0,
      fontSize,
      reverseAlign
    } = this.props;

    const isLeftSide = coordinate.x <= centerX;
    const isTopSide = coordinate.y <= centerY;

    const textX = isLeftSide
      ? reverseAlign
        ? coordinate.x + marginHorizontal
        : coordinate.x - marginHorizontal
      : reverseAlign
      ? coordinate.x - marginHorizontal
      : coordinate.x + marginHorizontal;
    const textY = isTopSide
      ? reverseAlign
        ? coordinate.y + marginVertical
        : coordinate.y - marginVertical
      : reverseAlign
      ? coordinate.y - marginVertical
      : coordinate.y + marginVertical;

    let textAnchor;

    // The reverseAlign logic is because for the circle labels,
    // we want to align them dependent of the side (right side, align left),
    // but we don't want that for the scala labels
    if (isLeftSide && !reverseAlign) textAnchor = 'end';
    if (isLeftSide && reverseAlign) textAnchor = 'start';
    if (!isLeftSide && !reverseAlign) textAnchor = 'start';
    if (!isLeftSide && reverseAlign) textAnchor = 'end';

    return (
      <text x={textX} y={textY} fill="#000" textAnchor={textAnchor} fontSize={fontSize}>
        {children}
      </text>
    );
  }
}
