/* eslint-disable react/prefer-stateless-function */
import React from 'react';

import logo from './goodjob_logo_small.png';
import styles from './Header.module.sass';

export default class Header extends React.Component {
  state = {
    prevScrollYPos: 0,
    navbarTop: 0
  };

  constructor(props) {
    super(props);
    this.headerRef = React.createRef();
  }

  componentDidMount() {
    this.setState(
      prevState => ({
        ...prevState,
        prevScrollYPos: window.pageYOffset,
        height: this.headerRef && this.headerRef.current && this.headerRef.current.clientHeight
      }),
      () => {
        window.addEventListener('scroll', this.handleScroll);
      }
    );
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { prevScrollYPos, height } = this.state;
    const currentScrollYPos = window.pageYOffset;

    if (prevScrollYPos > currentScrollYPos) {
      this.setState(prevState => ({
        ...prevState,
        navbarTop: 0,
        prevScrollYPos: currentScrollYPos
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        navbarTop: `-${height}px`,
        prevScrollYPos: currentScrollYPos
      }));
    }
  };

  render() {
    const { title } = this.props;
    const { navbarTop } = this.state;

    return (
      <div
        className={styles.title}
        style={{
          top: navbarTop
        }}
        ref={this.headerRef}
      >
        <div className={styles.titleWrapper}>
          <h2>{title}</h2>
        </div>
        <img src={logo} alt="GoodJob Logo" className={styles.logo} />
      </div>
    );
  }
}
