export const generatePointOnCircle = ({
  radius,
  countCorners,
  cornerNumber,
  centerX,
  centerY,
  alignTopCenter
}) => {
  const CIRCLE_ANGLE = 360;
  const BASE_ANGLE = CIRCLE_ANGLE / countCorners;
  const CURRENT_ANGLE = BASE_ANGLE * cornerNumber;
  // https://study.com/cimages/multimages/16/angles_on_the_unit_circle.png
  // Rotation starts from horizontal right line, but we want to start at the
  // top. So we basically need to rotate 270°, but subtract the angle
  const ADD_ROTATION_ANGLE = alignTopCenter ? 270 - BASE_ANGLE : 0;

  // https://stackoverflow.com/questions/35402609/point-on-circle-base-on-given-angle
  const x = centerX + radius * Math.cos(((CURRENT_ANGLE + ADD_ROTATION_ANGLE) * Math.PI) / 180);
  const y = centerY + radius * Math.sin(((CURRENT_ANGLE + ADD_ROTATION_ANGLE) * Math.PI) / 180);
  const point = { x, y };

  return point;
};

// The outer points of a n-gon basically lie on a circle
// So this function generates all these outer points (= the corners of the n-gon)
export const generateCornerCoordinates = ({ radius, countCorners, centerX, centerY }) => {
  const points = [...new Array(countCorners)].map((val, index) =>
    generatePointOnCircle({
      radius,
      countCorners: countCorners,
      cornerNumber: index + 1,
      centerX,
      centerY,
      alignTopCenter: true
    })
  );

  return points;
};

// Calculate distance between two points
export const calculateDistance = ({ startPoint, endPoint }) =>
  Math.sqrt(Math.pow(endPoint.x - startPoint.x, 2) + Math.pow(endPoint.y - startPoint.y, 2));

// Generate a path string along the given points
export const generatePathString = ({ points }) => {
  const firstX = points[0].x;
  const firstY = points[0].y;
  const countCorners = points.length;

  return points.reduce((prevValue, currValue, index) => {
    const isLast = index + 1 === countCorners;
    const { x, y } = currValue;

    // WE REMOVED THE M HERE
    return `${prevValue} L${x} ${y} ${isLast ? `L${firstX} ${firstY} Z` : ''}`;
    // return `${prevValue} L${x} ${y} M${x} ${y} ${isLast ? `L${firstX} ${firstY} Z` : ''}`;
  }, `M${firstX} ${firstY} `);
};
