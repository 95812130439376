import React, { useCallback } from 'react';

import styles from './Scala.module.sass';
import ScalaValue from './ScalaValue';
import Separator from '../../shared/components/Separator';

const CIRCLE_SIZE = 30;

const Scala = ({ title, istValue, sollValue, values, labels, isCompanyQuestionnaire }) => {
  const renderValues = useCallback(() => {
    const istStyle = {
      backgroundColor: '#ff6f00',
      color: '#fff'
    };
    const sollStyle = {
      backgroundColor: '#1976d2',
      color: '#fff'
    };
    const sollAndIstStyle = {
      background: 'linear-gradient(to bottom right,#1976d2 50%,#ff6f00 50%)',
      color: '#fff'
    };

    // linear-gradient(to bottom right,#1976d2 49.5%,#ff6f00 50.5%)
    return values.map((value, index) => {
      const istValueInRange = istValue >= value.range[0] && istValue <= value.range[1];
      const sollValueInRange = sollValue >= value.range[0] && sollValue <= value.range[1];
      const sollAndIstValueInRange = istValueInRange && sollValueInRange;
      const style = sollAndIstValueInRange
        ? sollAndIstStyle
        : istValueInRange
        ? istStyle
        : sollValueInRange
        ? sollStyle
        : null;

      return (
        <ScalaValue
          style={style}
          borderColor={(istValueInRange || sollValueInRange) && 'rgba(255,255,255,0.4)'}
          title={value.title}
          description={value.description}
          key={index}
        />
      );
    });
  }, [istValue, sollValue, values]);

  return (
    <div className={styles.wrapper}>
      {title && (
        <React.Fragment>
          <div className={styles.title}>
            <h3>{title}</h3>
            <Separator
              style={{
                marginTop: 0
              }}
            />
          </div>
        </React.Fragment>
      )}
      {isCompanyQuestionnaire && (
        <h3 style={{ textAlign: 'center', marginBottom: '4rem' }}>
          Durchschnittswerte SOLL und IST
        </h3>
      )}

      <div className={styles.lineWrapper}>
        <div className={styles.line} />
        {!istValue && istValue !== 0 ? null : (
          <div
            className={styles.circleWrapper}
            style={{
              left: `calc(${istValue}% - ${CIRCLE_SIZE / 2}px)`
            }}
          >
            <div
              className={styles.value}
              style={{
                color: '#ff6f00'
              }}
            >
              {Math.round(istValue)}
            </div>
            <div
              className={styles.circle}
              style={{
                backgroundColor: '#ff6f00'
              }}
            />
          </div>
        )}
        {!sollValue && sollValue !== 0 ? null : (
          <div
            className={styles.circleWrapper}
            style={{
              left: `calc(${sollValue}% - ${CIRCLE_SIZE / 2}px)`
            }}
          >
            <div
              className={styles.value}
              style={{
                top: '30px',
                color: '#1976d2'
              }}
            >
              {Math.round(sollValue)}
            </div>
            <div
              className={styles.circle}
              style={{
                backgroundColor: '#1976d2'
              }}
            />
          </div>
        )}
        <div className={styles.options}>
          {labels.map(sliderLabel => (
            <div key={sliderLabel}>{sliderLabel}</div>
          ))}
        </div>
      </div>

      <div className={styles.scalaValuesWrapper}>
        <div className={styles.scalaValues}>{renderValues()}</div>
      </div>
    </div>
  );
};

export default Scala;
