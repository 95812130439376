import React from 'react';

import Circle from './Circle';
import TextLabel from './TextLabel';
import {
  generatePointOnCircle,
  generateCornerCoordinates,
  calculateDistance,
  generatePathString
} from './utils';
import Button from '../Button';
import styles from './RadarChart.module.sass';

export default class RadarChart extends React.Component {
  state = {
    filter: null
  };

  constructor(props) {
    super(props);
    this.size = props.options.radarSize;
    this.countCorners = Object.keys(props.options.labels).length;
    this.centerX = this.size / 2;
    this.centerY = this.size / 2;
    this.radius = this.size / 2;
    this.cornerCoordinates = generateCornerCoordinates({
      radius: this.radius,
      countCorners: this.countCorners,
      centerX: this.centerX,
      centerY: this.centerY
    });
    this.transformedData = this.transformData(props.data, props.options);
  }

  componentDidMount() {
    const { options } = this.props;

    if (options.enableFilter && options.filterOptions) {
      this.setState(prevState => ({
        ...prevState,
        filter: options.filterOptions.defaultFilter
      }));
    }
  }

  setFilter = filter => {
    this.setState({ filter });
  };

  renderFilters = () => {
    const { filter } = this.state;
    const { options } = this.props;

    return Object.keys(options.filterOptions.labels).map(labelKey => (
      <Button
        outlined={filter !== labelKey}
        isLink
        rounded={false}
        hover={false}
        onClick={() => this.setFilter(labelKey)}
        key={labelKey}
      >
        {options.filterOptions.labels[labelKey]}
      </Button>
    ));
  };

  // Render in correct order
  renderScalaLabels = () => {
    const { options } = this.props;

    return this.cornerCoordinates.map((cornerPoint, index) => (
      <TextLabel
        coordinate={cornerPoint}
        centerX={this.centerX}
        centerY={this.centerY}
        marginVertical={10}
        marginHorizontal={10}
        textAnchor="inherit"
        fontSize={14}
        key={`label-${cornerPoint.x}-${cornerPoint.y}`}
      >
        {options.labels[options.dataOrder[index]]}
      </TextLabel>
    ));
  };

  renderGrid = ({ count }) => {
    const paths = [];

    // We go backwards so that z-index order is correct
    for (let i = count; i > 0; i--) {
      const isLast = i === count;
      const radius = (this.radius / count) * i;
      const points = generateCornerCoordinates({
        radius,
        countCorners: this.countCorners,
        centerX: this.centerX,
        centerY: this.centerY
      });
      const path = generatePathString({ points });

      paths.push(
        <path
          d={path}
          key={`polygongrid-${i}`}
          stroke="#999"
          fill="none"
          fillOpacity="1"
          strokeWidth={isLast ? '0.8' : '0.1'}
        />
      );
    }

    return paths;
  };

  renderLine = ({ startPoint, endPoint, stroke, strokeWidth, fill, fillOpacity, ...rest }) => {
    return (
      <path
        d={`M${startPoint.y} ${startPoint.y} L${endPoint.x} ${endPoint.y} Z`}
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill={fill}
        fillOpacity={fillOpacity}
        transform={`translate(${this.size}), rotate(180deg)`}
        {...rest}
      />
    );
  };

  renderCenterToCornerLines = () => {
    const centerPoint = { x: this.centerX, y: this.centerY };

    // For each corner, draw a line from center to this corner
    return this.cornerCoordinates.map((cornerPoint, index) => {
      const id = `centerToCornerLine-${index}`;

      return this.renderLine({
        startPoint: centerPoint,
        endPoint: cornerPoint,
        key: `shape-${cornerPoint.x}|${cornerPoint.y}`,
        stroke: '#999',
        strokeWidth: '0.2',
        fill: 'none',
        fillOpacity: '0',
        id
      });
    });
  };

  renderScalaLegends = () => {
    const {
      options: { dataOrder, legends }
    } = this.props;

    // const centerPoint = { x: this.centerX, y: this.centerY };

    // For each corner, draw a line from center to this corner
    return this.cornerCoordinates.map((cornerPoint, index) => {
      // const distance = calculateDistance({ startPoint: centerPoint, endPoint: cornerPoint });
      const id = `centerToCornerLine-${index}`;
      const currentLabelKey = dataOrder[index];
      const legend = legends[currentLabelKey];

      return (
        <React.Fragment key={id}>
          <text fill="rgba(0,0,0,0.4)" fontSize={12} x="40" y="0">
            <textPath xlinkHref={`#${id}`}>{legend[0]}</textPath>
          </text>
          <text fill="rgba(0,0,0,0.4)" fontSize={12} x={150} y="0" textAnchor="start">
            <textPath xlinkHref={`#${id}`}>{legend[1]}</textPath>
          </text>
        </React.Fragment>
      );
    });
  };

  renderDataShapes = () => {
    const { options } = this.props;
    const { filter } = this.state;
    const { enableFilter } = options;

    return this.transformedData
      .filter(({ filterKey }) => enableFilter && (filter === filterKey || filter === 'all'))
      .map(({ pathString, color }) => (
        <path
          d={pathString}
          strokeWidth="2"
          strokeLinejoin="round"
          stroke={color}
          fill="none"
          fillOpacity="0.4"
          key={pathString}
        />
      ));
  };

  renderDataCircles = () => {
    const {
      options: { enableFilter }
    } = this.props;
    const { filter } = this.state;

    return this.transformedData
      .filter(({ filterKey }) => enableFilter && (filter === filterKey || filter === 'all'))
      .map(({ points, color }) =>
        points.map(point => (
          <Circle
            coordinate={point}
            color={color}
            centerX={this.centerX}
            centerY={this.centerY}
            key={`circle-${point.x}-${point.y}`}
            value={Math.round(point.value)}
          />
        ))
      );
  };

  renderData = () => {
    return (
      <React.Fragment>
        {/* Render the data shapes / paths */}
        {this.renderDataShapes()}
        {/* Render the circle points */}
        {this.renderDataCircles()}
      </React.Fragment>
    );
  };

  renderInnerLabels = cornerNumber => {
    const radius = 50;
    const countCorners = this.countCorners;
    const centerX = this.centerX;
    const centerY = this.centerY;
    const alignTopCenter = true;

    const point = generatePointOnCircle({
      radius,
      countCorners,
      cornerNumber,
      centerX,
      centerY,
      alignTopCenter
    });

    return { point };
  };

  // Re-shape the data we get, so that we have access to the points for each
  // data value, the pathstring and the color, for easier rendering
  transformData = (data, options) => {
    const transformedData = data.map(dataSet => {
      const { color, filterKey } = dataSet.meta;
      const sortedValues = options.dataOrder.map(labelKey => ({
        labelKey,
        value: dataSet.values[labelKey]
      }));

      const points = sortedValues.map(({ value }, index) => {
        const cornerCoordinate = this.cornerCoordinates[index];

        // Start Point is our center
        // Target Point is the calculated point on the circle = the corner
        const centerPoint = {
          x: this.centerX,
          y: this.centerY
        };

        // Calculate the distance between those center and corner
        const distance = calculateDistance({ startPoint: centerPoint, endPoint: cornerCoordinate });

        // Next we need to map our values to distance
        // The distance length = 100%
        // We need to know, how many percent our value is, so divide value by the max range
        const maxRange = options.scala[1];
        const valueToPercent = value / maxRange;

        // We move from the center this number
        // So x% of the whole distance
        const distanceCenterToValue = distance * valueToPercent;

        // This is the formulare to calculate the new point x3/y3 on the line
        // between two points
        // More info: https://math.stackexchange.com/questions/2045174/how-to-find-a-point-between-two-points-with-given-distance
        const targetCoordinateX =
          centerPoint.x + (distanceCenterToValue / distance) * (cornerCoordinate.x - centerPoint.x);
        const targetCoordinateY =
          centerPoint.y + (distanceCenterToValue / distance) * (cornerCoordinate.y - centerPoint.y);

        return {
          x: targetCoordinateX,
          y: targetCoordinateY,
          value
        };
      });

      const pathString = generatePathString({ points });

      return {
        points,
        pathString,
        color,
        filterKey
      };
    });

    return transformedData;
  };

  render() {
    const { options } = this.props;

    return (
      <div style={{ maxWidth: '100%' }}>
        <svg
          version="1"
          xmlns="http://www.w3.org/2000/svg"
          width={this.size * 1.4}
          height={this.size}
          viewBox={`0 0 ${this.size} ${this.size}`}
          style={{
            maxWidth: '100%'
          }}
        >
          <g style={{ transform: 'scale(0.8)', transformOrigin: 'center' }}>
            {this.renderGrid({ count: 10 })}
            {this.renderCenterToCornerLines()}
            {this.renderScalaLabels()}
            {this.renderScalaLegends()}
            {this.renderData()}
          </g>
        </svg>

        {options.enableFilter && <div className={styles.radarFilters}>{this.renderFilters()}</div>}
      </div>
    );
  }
}
