export const apiUrl = process.env.REACT_APP_API_URL;

const parseJson = response => response.text().then(text => (text ? JSON.parse(text) : {}));

export const request = (url, options = {}) => {
  const { method = 'GET', body, headers, params = {} } = options;

  const paramsString = Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join('&');
  const fetchUrl = Object.keys(params).length > 0 ? `${url}?${paramsString}` : url;

  let fetchHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...headers
  };

  const fetchOptions = {
    method,
    body: JSON.stringify(body),
    headers: fetchHeaders
  };

  return fetch(fetchUrl, fetchOptions).then(parseJson);
};
