/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import classNames from 'classnames';

import ProgressBar from '../ProgressBar';
import styles from './Table.module.sass';
import Button from '../../shared/components/Button';

const benchmarkKeyValueMap = {
  company_size_benchmark: 'Unternehmensgröße',
  company_industry_benchmark: 'Branche',
  position_benchmark: 'Position',
  function_benchmark: 'Funktion',
  income_benchmark: 'Verdienst',
  country_benchmark: 'Land',
  belonging_duration_benchmark: 'Zugehörigkeitsdauer zum Unternehmen',
  age_benchmark: 'Alter',
  gender_benchmark: 'Geschlecht'
};

const groupTitleMap = {
  Arbeitsplatz: 'arbeitsplatzgapavg',
  Entwicklung: 'entwicklunggapavg',
  Motivation: 'motivationgapavg',
  Recruiting: 'recruitinggapavg',
  Steuerung: 'steuerunggapavg',
  Führung: 'fuehrunggapavg',
  'Work Life Organisation': 'worklifeorganisationgapavg'
};

export default class Table extends React.Component {
  state = {
    showBenchmarks: {}
  };

  renderRows = () => {
    const { benchmarks = {}, data, questions } = this.props;
    const hasBenchmarks = Object.keys(benchmarks).length > 0;
    const screenWidthLess768 = window.innerWidth <= 768;

    const withGroupScores = Object.keys(data.scores).reduce((prevValue, groupTitle) => {
      // const groupDelta = Math.round(Math.abs(data.scores[groupTitle].gap));
      // const groupScorePercent = Math.round(100 - Math.abs(data.scores[groupTitle].gap));
      const groupScorePercent = Math.round(
        100 - Math.abs(data.scores[groupTitle].ist.average - data.scores[groupTitle].soll.average)
      );

      return {
        ...prevValue,
        [groupTitle]: {
          ...prevValue[groupTitle],
          groupScorePercent
        }
      };
    }, {});

    // We map through questions and not through the object because this array
    // is logically sorted, so we can keep the logic order (objects keys are sorted alphabetically)
    return questions.map(({ title }) => {
      const groupScorePercent = withGroupScores[title].groupScorePercent;

      return (
        <React.Fragment key={title}>
          <tr>
            <td data-column="Dimension">{title}</td>
            <td data-column={screenWidthLess768 ? 'Übereinstimmung' : 'SOLL-IST-Übereinstimmung'}>
              <div className={styles.groupScore}>
                <div className={styles.groupScorePercent}>{groupScorePercent}</div>
                <ProgressBar percent={groupScorePercent} />
              </div>
            </td>
            {hasBenchmarks && (
              <td data-column="Benchmarks">
                <Button
                  hover={false}
                  onClick={() =>
                    this.setState(prevState => ({
                      ...prevState,
                      showBenchmarks: {
                        ...prevState.showBenchmarks,
                        [title]: !this.state.showBenchmarks[title]
                      }
                    }))
                  }
                >
                  {!this.state.showBenchmarks[title]
                    ? 'Benchmarks anzeigen'
                    : 'Benchhmarks ausblenden'}
                </Button>
              </td>
            )}
          </tr>
          {hasBenchmarks && this.renderBenchmarks(title)}
        </React.Fragment>
      );
    });
  };

  renderBenchmarks = groupTitle => {
    const { benchmarks, benchmarkOptions } = this.props;

    return Object.keys(benchmarks).map(benchmarkKey => {
      const benchmark =
        100 - Math.round(benchmarks[benchmarkKey]['result'][groupTitleMap[groupTitle]]);
      const selectedBenchmarkOptionValue = benchmarks[benchmarkKey]['value'];
      const benchmarkOptionsValues = Object.keys(benchmarkOptions).map(
        key => benchmarkOptions[key]
      );

      const mergedBenchmarkOptionsArrays =
        benchmarkOptions && [].concat.apply([], benchmarkOptionsValues);
      const selectedBenchmarkOptionTitle =
        mergedBenchmarkOptionsArrays &&
        mergedBenchmarkOptionsArrays.find(({ value }) => value === selectedBenchmarkOptionValue);

      return (
        <tr
          className={classNames(styles.benchmarkRow, {
            [styles.hidden]: !this.state.showBenchmarks[groupTitle]
          })}
          key={`${groupTitle}-${benchmarkKey}`}
        >
          <td colSpan="1" data-column="Benchmark">
            {`${benchmarkKeyValueMap[benchmarkKey]}: ${selectedBenchmarkOptionTitle.label}`}
          </td>

          <td colSpan="2" data-column="Wert">
            <div className={styles.groupScore}>
              <div className={styles.groupScorePercent}>{benchmark}</div>
              <ProgressBar percent={benchmark} />
            </div>
          </td>
        </tr>
      );
    });
  };

  render() {
    const { benchmarks = {}, data, questions } = this.props;
    const hasBenchmarks = Object.keys(benchmarks).length > 0;

    return (
      <div className={styles.wrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.sticky}>Dimension</th>
              <th>SOLL-IST-Übereinstimmung</th>
              {hasBenchmarks && <th />}
            </tr>
          </thead>
          <tbody>{this.renderRows()}</tbody>
        </table>
      </div>
    );
  }
}
