import React from 'react';

import styles from './Separator.module.sass';

const Separator = props => (
  <div className={styles.separator} {...props}>
    <span className={styles.hr} />
  </div>
);

export default Separator;
