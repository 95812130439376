import React from 'react';

import styles from './HeartScore.module.sass';
import Heart from '../Heart';

class HeartScore extends React.Component {
  render() {
    const { score, wrapperStyle, ...rest } = this.props;

    return (
      <div className={styles.scoreWrapper}>
        <span className={styles.score}>Good</span>
        <span className={styles.score}>
          J
          <Heart
            style={{
              display: 'inline-block',
              ...wrapperStyle
            }}
            text={score}
            {...rest}
          />
          b
        </span>
      </div>
    );
  }
}

export default HeartScore;
