import React from 'react';

import TextLabel from './TextLabel';

export default class Circle extends React.Component {
  state = {
    hover: false
  };

  onMouseOver = () => {
    this.setState(prevState => ({ ...prevState, hover: true }));
  };

  onMouseLeave = () => {
    this.setState(prevState => ({ ...prevState, hover: false }));
  };

  render() {
    const { coordinate, centerX, centerY, color, value } = this.props;
    const { hover } = this.state;

    return (
      <g>
        <circle
          cx={coordinate.x}
          cy={coordinate.y}
          r="8"
          fill={hover ? color : '#FFF'}
          stroke={hover ? '#FFF' : color}
          strokeWidth={hover ? '2' : '1'}
          onMouseOver={this.onMouseOver}
          onMouseLeave={this.onMouseLeave}
          onFocus={this.onMouseOver}
        />
        {hover && (
          <TextLabel
            coordinate={coordinate}
            centerX={centerX}
            centerY={centerY}
            marginVertical={8}
            marginHorizontal={12}
            fontSize={24}
            reverseAlign={true}
          >
            {value}
          </TextLabel>
        )}
      </g>
    );
  }
}
