import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Report from './components/Report';
import styles from './App.module.sass';
import './shared/global.sass';

class App extends Component {
  render() {
    return (
      <Router>
        <div className={styles.App}>
          <Route path="/:reportId" component={Report} />
        </div>
      </Router>
    );
  }
}

export default App;
