import React from 'react';

import styles from './Scala.module.sass';

class ScalaValue extends React.Component {
  render() {
    const { title, description, borderColor, style, ...rest } = this.props;

    return (
      <div className={styles.scalaValueWrapper}>
        <div className={styles.scalaValue} {...rest}>
          <div
            className={styles.scalaValueTitle}
            style={{
              borderBottomColor: borderColor ? borderColor : 'rgba(0,0,0,0.2)',
              ...style
            }}
          >
            {title}
          </div>
          <div className={styles.scalaValueDescription}>{description}</div>
        </div>
      </div>
    );
  }
}

export default ScalaValue;
