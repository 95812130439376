/* eslint-disable react/button-has-type */
import React from 'react';
import classNames from 'classnames';

import styles from './Button.module.sass';

function Button({ children, rounded = true, hover = true, type, href, isLink, outlined, ...rest }) {
  if (isLink) {
    return (
      <a
        className={classNames(styles.button, {
          [styles.outlined]: outlined,
          [styles.rounded]: rounded,
          [styles.hover]: hover
        })}
        href={href}
        {...rest}
      >
        {children}
      </a>
    );
  }

  return (
    <button
      className={classNames(styles.button, {
        [styles.outlined]: outlined,
        [styles.rounded]: rounded,
        [styles.hover]: hover
      })}
      type={type}
      {...rest}
    >
      {children}
    </button>
  );
}

export default Button;
